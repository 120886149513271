<template>
  <v-container fluid>
    <v-data-table
      class="pt-3"
      flat
      :loading="loading ? '#144881' : null"
      :headers="headers"
      :search="searchData"
      :items="Ecues"
      :items-per-page="5"
      sort-by=""
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title
            >Liste des <span class="font-weight-bold">ECUE</span> de l'Ue :
            {{ Ue.name }}</v-toolbar-title
          >

          <v-spacer></v-spacer>
          <template>
            <v-btn
              color="#144881"
              dark
              class="mb-2 mr-8"
              @click="addItem"
              v-bind="attrs"
              outlined
              pill
              v-on="on"
              rounded
              v-if="UserProfile.role == 'Administrateur'"
            >
              <v-icon left> mdi-account-outline </v-icon>
              Ajout
            </v-btn>
          </template>
        </v-toolbar>

        <!-- <div>
          <h3>Niveau : <span>{{Ue.level}}</span></h3>
        </div> -->
        <v-container fluid>
          <v-expansion-panels flat style="border: 1px solid #144881">
            <v-expansion-panel class="elevation-0 pa-0">
              <v-expansion-panel-header>
                Tri / Recherche ...
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12" md="12">
                  <v-text-field
                    color="#144881"
                    label="Recherche "
                    class="pt-5 pa-2"
                    filled
                    prepend-inner-icon="mdi-magnify"
                    dense
                    v-model="searchData"
                    outlined
                    flat
                    background-color="grey lighten-4"
                    rounded
                  ></v-text-field>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu elevation="0" right offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="mr-md-10">
              <v-icon color="success" size="30">mdi-forwardburger</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-tooltip
              v-if="
                ['Administrateur', 'Manager BVU'].includes(UserProfile.role)
              "
              bottom
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <router-link :to="'/ue/ecue/' + item._id + '/student'">
                  <v-btn class="ml-3" icon>
                    <v-icon size="20" v-bind="attrs" v-on="on" color="primary"
                      >mdi-account-multiple</v-icon
                    >
                  </v-btn>
                </router-link>
              </template>
              <span>Liste des étudiants de l'ecue</span>
            </v-tooltip>
            <v-tooltip
              v-if="UserProfile.role == 'Administrateur'"
              bottom
              color="green"
            >
              <template v-slot:activator="{ on, attrs }">
                <router-link :to="'/ue/ecue/' + item._id">
                  <v-btn class="ml-3" icon>
                    <v-icon size="20" v-bind="attrs" v-on="on" color="green"
                      >mdi-arrow-right</v-icon
                    >
                  </v-btn>
                </router-link>
              </template>
              <span>Déveloper</span>
            </v-tooltip>
            <v-tooltip
              v-if="UserProfile.role == 'Administrateur'"
              bottom
              color="warning"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-3" icon>
                  <v-icon
                    size="20"
                    @click="updateItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    color="warning"
                    >mdi-pencil-box-multiple-outline</v-icon
                  >
                </v-btn>
              </template>
              <span>Modifier</span>
            </v-tooltip>
            <v-tooltip
              v-if="UserProfile.role == 'Administrateur'"
              bottom
              color="red"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-3" icon>
                  <v-icon
                    size="20"
                    @click="deleteItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog v-model="editorDialog" max-width="1000px" class="black">
      <editor-x
        v-if="editorDialog"
        :fields="fields"
        :model="Model"
        :callback="callBack"
        @success="successEditor"
        :title="
          Model && Model._id ? `Modification de l'ECUE` : `Ajout de l'ECUE`
        "
        :actionBtnLabel="Model && Model._id ? `Modifier` : `Ajouter`"
      />
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="1000px" class="black">
      <delete-dialog
        :id="Model && Model._id ? Model._id : ''"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defaultMethods, messages } from "../../utils/data";
import { addEcue, updateEcue, deleteEcue } from "../../api/user";
import EditorX from "../../components/universal/EditorX.vue";
import DeleteDialog from "../../components/universal/DeleteDialog.vue";
export default {
  name: "Ue",
  components: {
    EditorX,
    DeleteDialog,
  },
  data: () => ({
    callBack: "",
    searchData: "",
    dialog: false,
    loading: false,
    headers: [
      {
        text: "Actions",
        width: "150px",
        align: "left",
        value: "actions",
        sortable: false,
      },
      {
        text: "Nom",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Acronyme",
        sortable: false,
        value: "acronym",
      },
      {
        text: "Description",
        sortable: false,
        value: "description",
      },
      {
        text: "Crédit",
        sortable: false,
        value: "unit",
      },
    ],
    editedIndex: -1,
    Model: {},
    defaultItem: {},
    editorDialog: false,
    deleteDialog: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(["Ecues", "Ue", "UserProfile"]),

    fields() {
      return [
        {
          name: "name",
          label: "Nom",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "acronym",
          label: "Acronyme",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "unit",
          label: "Crédit",
          type: "Number",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "description",
          label: "Descripiton",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "ue",
          label: "",
          type: "Static",
          value: this.$route.params.id,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
      ];
    },
  },

  watch: {},
  methods: {
    ...mapActions(["getEcues"]),
    closeForm() {
      this.Model = {};
      this.editorDialog = false;
      this.editedIndex = -1;
    },

    async init() {
      this.loading = true;
      try {
        await this.getEcues({ ue: this.$route.params.id });
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store,
          messages.failedToLoad("la faculté")
        );
      }
      this.loading = false;
    },
    successEditor() {
      this.editorDialog = false;
      this.deleteDialog = false;
      this.init();
    },
    addItem() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addEcue;
      this.editorDialog = true;
    },
    updateItem(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.editorDialog = true;
      this.callBack = updateEcue;
    },

    deleteItem(item) {
      this.deleteDialog = true;
      this.Model = Object.assign({}, item);
      this.callBack = deleteEcue;
    },
  },
};
</script>

<style >
</style>
